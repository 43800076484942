.loading {
  text-align: center;
}

.loading-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .loading-logo {
    animation: loading-spin infinite 20s linear;
  }
}

.loading-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes loading-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App {
  background-color: white;
}

header {
  height: 50px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
  text-indent: -10000px;
}

header.matchup {
  background: no-repeat url('/logos/matchup.png');
  background-size: 200px 48px;
  background-position: center;
}

section {
  padding: 20px 0;
  text-align: center;
}

.wizard-form {
  padding: 0 20px;
}

.wizard-form>div {
  margin: 20px 0;
}

@media (min-width: 680px) {
  .wizard-form {
    width: 400px;
    margin: 0 auto;
  }
}
